@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Raleway";
  margin: 0;
  height: 100vh;
}

.ant-modal {
  top: 40px;
  width: 650px !important;
}

.ant-card-body {
  padding: 20px !important;
}

.logout-item {
  padding: 10px 16px;
  color: #141414;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.ant-table-tbody > tr > td {
  white-space: normal !important;
}

input,
textarea,
label {
  color: #000 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ant-input {
  font-size: 16px !important;
}

.ant-input:hover {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-select:hover {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-picker:hover {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-picker-focused {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #0b1e57;
  --antd-wave-shadow-color: #0b1e57;
}

.ant-switch-checked {
  background: #0b1e57;
}

a:hover {
  color: #e04893;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand span {
  margin-left: 10px !important;
}

.user-img-dragger.hasPreview
  .ant-upload
  .ant-upload-btn
  .ant-upload-drag-container
  p {
  opacity: 0;
}

.user-img-dragger {
  max-width: 100px;
}

.ant-menu-dark .ant-menu-item {
  color: #b59e22 !important;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fff !important;
}

.ant-layout .ant-layout-sider,
.ant-layout-header,
.ant-layout-sider-trigger,
.ant-menu-dark {
  background-color: #830351 !important;
  color: #f8ad2f !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #f8ad2f !important;
  color: #fff !important;
}

.ant-menu-dark .ant-menu-submenu-selected {
  background-color: #f8ad2f !important;
  color: #fff !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* COLORS */
/* ------------------------------------- */
/* ------------------------------------- */

.white {
  color: #fff !important;
}

.blue {
  color: rgba(0, 164, 224, 1) !important;
}

.green {
  color: #53b1a7 !important;
}

.grey {
  color: rgba(196, 196, 196, 1) !important;
}

.dark-blue {
  color: rgba(7, 27, 69, 1) !important;
}

.red {
  color: rgba(235, 91, 91, 1) !important;
}

.purple {
  color: rgba(164, 40, 134, 1) !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* BACKGROUNDS */
/* ------------------------------------- */
/* ------------------------------------- */

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light-blue {
  background-color: rgba(0, 164, 224, 1) !important;
}

.bg-blue {
  background-color: rgba(0, 48, 164, 1) !important;
}

.bg-green {
  background-color: rgba(174, 204, 55, 1) !important;
}

.bg-yellow {
  background-color: rgba(254, 204, 25, 1) !important;
}

.bg-purple {
  background-color: rgba(164, 40, 134, 1) !important;
}

.bg-dark-blue {
  background-color: rgba(7, 27, 69, 1) !important;
}

.bg-grey {
  background-color: rgba(196, 196, 196, 1) !important;
}

.bg-light-grey {
  background-color: rgba(244, 244, 244, 1) !important;
}

.bg-red {
  background-color: rgba(235, 91, 91, 1) !important;
}

.bg-center {
  background-position: center !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* FONTS/TEXT STYLES */
/* ------------------------------------- */
/* ------------------------------------- */

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.bolder {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* TEXT */
/* ------------------------------------- */
/* ------------------------------------- */

p {
  color: #414141 !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ANTD */
/* ------------------------------------- */
/* ------------------------------------- */

.ant-layout {
  background: #fff;
  height: 100%;
}

.ant-layout-header.web {
  height: 85px;
  position: fixed;
  z-index: 1071;
}

.ant-btn {
  box-shadow: none !important;
  border-radius: 0 !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* FLEX */
/* ------------------------------------- */
/* ------------------------------------- */

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

/* ----------- */
/* JUSTIFY-CONTENT */
/* ----------- */

.jc-center {
  justify-content: center !important;
}

.jc-start {
  justify-content: flex-start !important;
}

.jc-end {
  justify-content: flex-end !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-sa {
  justify-content: space-around !important;
}

/* ----------- */
/* ALIGN-ITEMS */
/* ----------- */

.ai-center {
  align-items: center !important;
}

.ai-start {
  align-items: flex-start !important;
}

.ai-end {
  align-items: flex-end !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

.w-0 {
  width: 0% !important;
}

.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-27 {
  width: 27% !important;
}

.w-28 {
  width: 28% !important;
}

.w-29 {
  width: 29% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-37 {
  width: 37% !important;
}

.w-38 {
  width: 38% !important;
}

.w-39 {
  width: 39% !important;
}

.w-40 {
  width: 40% !important;
}

.w-41 {
  width: 41% !important;
}

.w-42 {
  width: 42% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52% !important;
}

.w-53 {
  width: 53% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.w-58 {
  width: 58% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-61 {
  width: 61% !important;
}

.w-62 {
  width: 62% !important;
}

.w-63 {
  width: 63% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.w-68 {
  width: 68% !important;
}

.w-69 {
  width: 69% !important;
}

.w-70 {
  width: 70% !important;
}

.w-71 {
  width: 71% !important;
}

.w-72 {
  width: 72% !important;
}

.w-73 {
  width: 73% !important;
}

.w-74 {
  width: 74% !important;
}

.w-75 {
  width: 75% !important;
}

.w-76 {
  width: 76% !important;
}

.w-77 {
  width: 77% !important;
}

.w-78 {
  width: 78% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-82 {
  width: 82% !important;
}

.w-83 {
  width: 83% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.w-87 {
  width: 87% !important;
}

.w-88 {
  width: 88% !important;
}

.w-89 {
  width: 89% !important;
}

.w-90 {
  width: 90% !important;
}

.w-91 {
  width: 91% !important;
}

.w-92 {
  width: 92% !important;
}

.w-93 {
  width: 93% !important;
}

.w-94 {
  width: 94% !important;
}

.w-95 {
  width: 95% !important;
}

.w-96 {
  width: 96% !important;
}

.w-97 {
  width: 97% !important;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* TABLET WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

@media only screen and (max-width: 800px) {
  .tablet-w-0 {
    width: 0% !important;
  }

  .tablet-w-1 {
    width: 1% !important;
  }

  .tablet-w-2 {
    width: 2% !important;
  }

  .tablet-w-3 {
    width: 3% !important;
  }

  .tablet-w-4 {
    width: 4% !important;
  }

  .tablet-w-5 {
    width: 5% !important;
  }

  .tablet-w-6 {
    width: 6% !important;
  }

  .tablet-w-7 {
    width: 7% !important;
  }

  .tablet-w-8 {
    width: 8% !important;
  }

  .tablet-w-9 {
    width: 9% !important;
  }

  .tablet-w-10 {
    width: 10% !important;
  }

  .tablet-w-11 {
    width: 11% !important;
  }

  .tablet-w-12 {
    width: 12% !important;
  }

  .tablet-w-13 {
    width: 13% !important;
  }

  .tablet-w-14 {
    width: 14% !important;
  }

  .tablet-w-15 {
    width: 15% !important;
  }

  .tablet-w-16 {
    width: 16% !important;
  }

  .tablet-w-17 {
    width: 17% !important;
  }

  .tablet-w-18 {
    width: 18% !important;
  }

  .tablet-w-19 {
    width: 19% !important;
  }

  .tablet-w-20 {
    width: 20% !important;
  }

  .tablet-w-21 {
    width: 21% !important;
  }

  .tablet-w-22 {
    width: 22% !important;
  }

  .tablet-w-23 {
    width: 23% !important;
  }

  .tablet-w-24 {
    width: 24% !important;
  }

  .tablet-w-25 {
    width: 25% !important;
  }

  .tablet-w-26 {
    width: 26% !important;
  }

  .tablet-w-27 {
    width: 27% !important;
  }

  .tablet-w-28 {
    width: 28% !important;
  }

  .tablet-w-29 {
    width: 29% !important;
  }

  .tablet-w-30 {
    width: 30% !important;
  }

  .tablet-w-31 {
    width: 31% !important;
  }

  .tablet-w-32 {
    width: 32% !important;
  }

  .tablet-w-33 {
    width: 33% !important;
  }

  .tablet-w-34 {
    width: 34% !important;
  }

  .tablet-w-35 {
    width: 35% !important;
  }

  .tablet-w-36 {
    width: 36% !important;
  }

  .tablet-w-37 {
    width: 37% !important;
  }

  .tablet-w-38 {
    width: 38% !important;
  }

  .tablet-w-39 {
    width: 39% !important;
  }

  .tablet-w-40 {
    width: 40% !important;
  }

  .tablet-w-41 {
    width: 41% !important;
  }

  .tablet-w-42 {
    width: 42% !important;
  }

  .tablet-w-43 {
    width: 43% !important;
  }

  .tablet-w-44 {
    width: 44% !important;
  }

  .tablet-w-45 {
    width: 45% !important;
  }

  .tablet-w-46 {
    width: 46% !important;
  }

  .tablet-w-47 {
    width: 47% !important;
  }

  .tablet-w-48 {
    width: 48% !important;
  }

  .tablet-w-49 {
    width: 49% !important;
  }

  .tablet-w-50 {
    width: 50% !important;
  }

  .tablet-w-51 {
    width: 51% !important;
  }

  .tablet-w-52 {
    width: 52% !important;
  }

  .tablet-w-53 {
    width: 53% !important;
  }

  .tablet-w-54 {
    width: 54% !important;
  }

  .tablet-w-55 {
    width: 55% !important;
  }

  .tablet-w-56 {
    width: 56% !important;
  }

  .tablet-w-57 {
    width: 57% !important;
  }

  .tablet-w-58 {
    width: 58% !important;
  }

  .tablet-w-59 {
    width: 59% !important;
  }

  .tablet-w-60 {
    width: 60% !important;
  }

  .tablet-w-61 {
    width: 61% !important;
  }

  .tablet-w-62 {
    width: 62% !important;
  }

  .tablet-w-63 {
    width: 63% !important;
  }

  .tablet-w-64 {
    width: 64% !important;
  }

  .tablet-w-65 {
    width: 65% !important;
  }

  .tablet-w-66 {
    width: 66% !important;
  }

  .tablet-w-67 {
    width: 67% !important;
  }

  .tablet-w-68 {
    width: 68% !important;
  }

  .tablet-w-69 {
    width: 69% !important;
  }

  .tablet-w-70 {
    width: 70% !important;
  }

  .tablet-w-71 {
    width: 71% !important;
  }

  .tablet-w-72 {
    width: 72% !important;
  }

  .tablet-w-73 {
    width: 73% !important;
  }

  .tablet-w-74 {
    width: 74% !important;
  }

  .tablet-w-75 {
    width: 75% !important;
  }

  .tablet-w-76 {
    width: 76% !important;
  }

  .tablet-w-77 {
    width: 77% !important;
  }

  .tablet-w-78 {
    width: 78% !important;
  }

  .tablet-w-79 {
    width: 79% !important;
  }

  .tablet-w-80 {
    width: 80% !important;
  }

  .tablet-w-81 {
    width: 81% !important;
  }

  .tablet-w-82 {
    width: 82% !important;
  }

  .tablet-w-83 {
    width: 83% !important;
  }

  .tablet-w-84 {
    width: 84% !important;
  }

  .tablet-w-85 {
    width: 85% !important;
  }

  .tablet-w-86 {
    width: 86% !important;
  }

  .tablet-w-87 {
    width: 87% !important;
  }

  .tablet-w-88 {
    width: 88% !important;
  }

  .tablet-w-89 {
    width: 89% !important;
  }

  .tablet-w-90 {
    width: 90% !important;
  }

  .tablet-w-91 {
    width: 91% !important;
  }

  .tablet-w-92 {
    width: 92% !important;
  }

  .tablet-w-93 {
    width: 93% !important;
  }

  .tablet-w-94 {
    width: 94% !important;
  }

  .tablet-w-95 {
    width: 95% !important;
  }

  .tablet-w-96 {
    width: 96% !important;
  }

  .tablet-w-97 {
    width: 97% !important;
  }

  .tablet-w-98 {
    width: 98% !important;
  }

  .tablet-w-99 {
    width: 99% !important;
  }

  .tablet-w-100 {
    width: 100% !important;
  }
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MOBBILE WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

@media only screen and (max-width: 600px) {
  .mobile-w-0 {
    width: 0% !important;
  }

  .mobile-w-1 {
    width: 1% !important;
  }

  .mobile-w-2 {
    width: 2% !important;
  }

  .mobile-w-3 {
    width: 3% !important;
  }

  .mobile-w-4 {
    width: 4% !important;
  }

  .mobile-w-5 {
    width: 5% !important;
  }

  .mobile-w-6 {
    width: 6% !important;
  }

  .mobile-w-7 {
    width: 7% !important;
  }

  .mobile-w-8 {
    width: 8% !important;
  }

  .mobile-w-9 {
    width: 9% !important;
  }

  .mobile-w-10 {
    width: 10% !important;
  }

  .mobile-w-11 {
    width: 11% !important;
  }

  .mobile-w-12 {
    width: 12% !important;
  }

  .mobile-w-13 {
    width: 13% !important;
  }

  .mobile-w-14 {
    width: 14% !important;
  }

  .mobile-w-15 {
    width: 15% !important;
  }

  .mobile-w-16 {
    width: 16% !important;
  }

  .mobile-w-17 {
    width: 17% !important;
  }

  .mobile-w-18 {
    width: 18% !important;
  }

  .mobile-w-19 {
    width: 19% !important;
  }

  .mobile-w-20 {
    width: 20% !important;
  }

  .mobile-w-21 {
    width: 21% !important;
  }

  .mobile-w-22 {
    width: 22% !important;
  }

  .mobile-w-23 {
    width: 23% !important;
  }

  .mobile-w-24 {
    width: 24% !important;
  }

  .mobile-w-25 {
    width: 25% !important;
  }

  .mobile-w-26 {
    width: 26% !important;
  }

  .mobile-w-27 {
    width: 27% !important;
  }

  .mobile-w-28 {
    width: 28% !important;
  }

  .mobile-w-29 {
    width: 29% !important;
  }

  .mobile-w-30 {
    width: 30% !important;
  }

  .mobile-w-31 {
    width: 31% !important;
  }

  .mobile-w-32 {
    width: 32% !important;
  }

  .mobile-w-33 {
    width: 33% !important;
  }

  .mobile-w-34 {
    width: 34% !important;
  }

  .mobile-w-35 {
    width: 35% !important;
  }

  .mobile-w-36 {
    width: 36% !important;
  }

  .mobile-w-37 {
    width: 37% !important;
  }

  .mobile-w-38 {
    width: 38% !important;
  }

  .mobile-w-39 {
    width: 39% !important;
  }

  .mobile-w-40 {
    width: 40% !important;
  }

  .mobile-w-41 {
    width: 41% !important;
  }

  .mobile-w-42 {
    width: 42% !important;
  }

  .mobile-w-43 {
    width: 43% !important;
  }

  .mobile-w-44 {
    width: 44% !important;
  }

  .mobile-w-45 {
    width: 45% !important;
  }

  .mobile-w-46 {
    width: 46% !important;
  }

  .mobile-w-47 {
    width: 47% !important;
  }

  .mobile-w-48 {
    width: 48% !important;
  }

  .mobile-w-49 {
    width: 49% !important;
  }

  .mobile-w-50 {
    width: 50% !important;
  }

  .mobile-w-51 {
    width: 51% !important;
  }

  .mobile-w-52 {
    width: 52% !important;
  }

  .mobile-w-53 {
    width: 53% !important;
  }

  .mobile-w-54 {
    width: 54% !important;
  }

  .mobile-w-55 {
    width: 55% !important;
  }

  .mobile-w-56 {
    width: 56% !important;
  }

  .mobile-w-57 {
    width: 57% !important;
  }

  .mobile-w-58 {
    width: 58% !important;
  }

  .mobile-w-59 {
    width: 59% !important;
  }

  .mobile-w-60 {
    width: 60% !important;
  }

  .mobile-w-61 {
    width: 61% !important;
  }

  .mobile-w-62 {
    width: 62% !important;
  }

  .mobile-w-63 {
    width: 63% !important;
  }

  .mobile-w-64 {
    width: 64% !important;
  }

  .mobile-w-65 {
    width: 65% !important;
  }

  .mobile-w-66 {
    width: 66% !important;
  }

  .mobile-w-67 {
    width: 67% !important;
  }

  .mobile-w-68 {
    width: 68% !important;
  }

  .mobile-w-69 {
    width: 69% !important;
  }

  .mobile-w-70 {
    width: 70% !important;
  }

  .mobile-w-71 {
    width: 71% !important;
  }

  .mobile-w-72 {
    width: 72% !important;
  }

  .mobile-w-73 {
    width: 73% !important;
  }

  .mobile-w-74 {
    width: 74% !important;
  }

  .mobile-w-75 {
    width: 75% !important;
  }

  .mobile-w-76 {
    width: 76% !important;
  }

  .mobile-w-77 {
    width: 77% !important;
  }

  .mobile-w-78 {
    width: 78% !important;
  }

  .mobile-w-79 {
    width: 79% !important;
  }

  .mobile-w-80 {
    width: 80% !important;
  }

  .mobile-w-81 {
    width: 81% !important;
  }

  .mobile-w-82 {
    width: 82% !important;
  }

  .mobile-w-83 {
    width: 83% !important;
  }

  .mobile-w-84 {
    width: 84% !important;
  }

  .mobile-w-85 {
    width: 85% !important;
  }

  .mobile-w-86 {
    width: 86% !important;
  }

  .mobile-w-87 {
    width: 87% !important;
  }

  .mobile-w-88 {
    width: 88% !important;
  }

  .mobile-w-89 {
    width: 89% !important;
  }

  .mobile-w-90 {
    width: 90% !important;
  }

  .mobile-w-91 {
    width: 91% !important;
  }

  .mobile-w-92 {
    width: 92% !important;
  }

  .mobile-w-93 {
    width: 93% !important;
  }

  .mobile-w-94 {
    width: 94% !important;
  }

  .mobile-w-95 {
    width: 95% !important;
  }

  .mobile-w-96 {
    width: 96% !important;
  }

  .mobile-w-97 {
    width: 97% !important;
  }

  .mobile-w-98 {
    width: 98% !important;
  }

  .mobile-w-99 {
    width: 99% !important;
  }

  .mobile-w-100 {
    width: 100% !important;
  }
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MAX-WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

.maxw-0 {
  max-width: 0px !important;
}

.maxw-1 {
  max-width: 1px !important;
}

.maxw-2 {
  max-width: 2px !important;
}

.maxw-3 {
  max-width: 3px !important;
}

.maxw-4 {
  max-width: 4px !important;
}

.maxw-5 {
  max-width: 5px !important;
}

.maxw-6 {
  max-width: 6px !important;
}

.maxw-7 {
  max-width: 7px !important;
}

.maxw-8 {
  max-width: 8px !important;
}

.maxw-9 {
  max-width: 9px !important;
}

.maxw-10 {
  max-width: 10px !important;
}

.maxw-11 {
  max-width: 11px !important;
}

.maxw-12 {
  max-width: 12px !important;
}

.maxw-13 {
  max-width: 13px !important;
}

.maxw-14 {
  max-width: 14px !important;
}

.maxw-15 {
  max-width: 15px !important;
}

.maxw-16 {
  max-width: 16px !important;
}

.maxw-17 {
  max-width: 17px !important;
}

.maxw-18 {
  max-width: 18px !important;
}

.maxw-19 {
  max-width: 19px !important;
}

.maxw-20 {
  max-width: 20px !important;
}

.maxw-21 {
  max-width: 21px !important;
}

.maxw-22 {
  max-width: 22px !important;
}

.maxw-23 {
  max-width: 23px !important;
}

.maxw-24 {
  max-width: 24px !important;
}

.maxw-25 {
  max-width: 25px !important;
}

.maxw-26 {
  max-width: 26px !important;
}

.maxw-27 {
  max-width: 27px !important;
}

.maxw-28 {
  max-width: 28px !important;
}

.maxw-29 {
  max-width: 29px !important;
}

.maxw-30 {
  max-width: 30px !important;
}

.maxw-31 {
  max-width: 31px !important;
}

.maxw-32 {
  max-width: 32px !important;
}

.maxw-33 {
  max-width: 33px !important;
}

.maxw-34 {
  max-width: 34px !important;
}

.maxw-35 {
  max-width: 35px !important;
}

.maxw-36 {
  max-width: 36px !important;
}

.maxw-37 {
  max-width: 37px !important;
}

.maxw-38 {
  max-width: 38px !important;
}

.maxw-39 {
  max-width: 39px !important;
}

.maxw-40 {
  max-width: 40px !important;
}

.maxw-41 {
  max-width: 41px !important;
}

.maxw-42 {
  max-width: 42px !important;
}

.maxw-43 {
  max-width: 43px !important;
}

.maxw-44 {
  max-width: 44px !important;
}

.maxw-45 {
  max-width: 45px !important;
}

.maxw-46 {
  max-width: 46px !important;
}

.maxw-47 {
  max-width: 47px !important;
}

.maxw-48 {
  max-width: 48px !important;
}

.maxw-49 {
  max-width: 49px !important;
}

.maxw-50 {
  max-width: 50px !important;
}

.maxw-51 {
  max-width: 51px !important;
}

.maxw-52 {
  max-width: 52px !important;
}

.maxw-53 {
  max-width: 53px !important;
}

.maxw-54 {
  max-width: 54px !important;
}

.maxw-55 {
  max-width: 55px !important;
}

.maxw-56 {
  max-width: 56px !important;
}

.maxw-57 {
  max-width: 57px !important;
}

.maxw-58 {
  max-width: 58px !important;
}

.maxw-59 {
  max-width: 59px !important;
}

.maxw-60 {
  max-width: 60px !important;
}

.maxw-61 {
  max-width: 61px !important;
}

.maxw-62 {
  max-width: 62px !important;
}

.maxw-63 {
  max-width: 63px !important;
}

.maxw-64 {
  max-width: 64px !important;
}

.maxw-65 {
  max-width: 65px !important;
}

.maxw-66 {
  max-width: 66px !important;
}

.maxw-67 {
  max-width: 67px !important;
}

.maxw-68 {
  max-width: 68px !important;
}

.maxw-69 {
  max-width: 69px !important;
}

.maxw-70 {
  max-width: 70px !important;
}

.maxw-71 {
  max-width: 71px !important;
}

.maxw-72 {
  max-width: 72px !important;
}

.maxw-73 {
  max-width: 73px !important;
}

.maxw-74 {
  max-width: 74px !important;
}

.maxw-75 {
  max-width: 75px !important;
}

.maxw-76 {
  max-width: 76px !important;
}

.maxw-77 {
  max-width: 77px !important;
}

.maxw-78 {
  max-width: 78px !important;
}

.maxw-79 {
  max-width: 79px !important;
}

.maxw-80 {
  max-width: 80px !important;
}

.maxw-81 {
  max-width: 81px !important;
}

.maxw-82 {
  max-width: 82px !important;
}

.maxw-83 {
  max-width: 83px !important;
}

.maxw-84 {
  max-width: 84px !important;
}

.maxw-85 {
  max-width: 85px !important;
}

.maxw-86 {
  max-width: 86px !important;
}

.maxw-87 {
  max-width: 87px !important;
}

.maxw-88 {
  max-width: 88px !important;
}

.maxw-89 {
  max-width: 89px !important;
}

.maxw-90 {
  max-width: 90px !important;
}

.maxw-91 {
  max-width: 91px !important;
}

.maxw-92 {
  max-width: 92px !important;
}

.maxw-93 {
  max-width: 93px !important;
}

.maxw-94 {
  max-width: 94px !important;
}

.maxw-95 {
  max-width: 95px !important;
}

.maxw-96 {
  max-width: 96px !important;
}

.maxw-97 {
  max-width: 97px !important;
}

.maxw-98 {
  max-width: 98px !important;
}

.maxw-99 {
  max-width: 99px !important;
}

.maxw-100 {
  max-width: 100px !important;
}

.maxw-101 {
  max-width: 101px !important;
}

.maxw-102 {
  max-width: 102px !important;
}

.maxw-103 {
  max-width: 103px !important;
}

.maxw-104 {
  max-width: 104px !important;
}

.maxw-105 {
  max-width: 105px !important;
}

.maxw-106 {
  max-width: 106px !important;
}

.maxw-107 {
  max-width: 107px !important;
}

.maxw-108 {
  max-width: 108px !important;
}

.maxw-109 {
  max-width: 109px !important;
}

.maxw-110 {
  max-width: 110px !important;
}

.maxw-111 {
  max-width: 111px !important;
}

.maxw-112 {
  max-width: 112px !important;
}

.maxw-113 {
  max-width: 113px !important;
}

.maxw-114 {
  max-width: 114px !important;
}

.maxw-115 {
  max-width: 115px !important;
}

.maxw-116 {
  max-width: 116px !important;
}

.maxw-117 {
  max-width: 117px !important;
}

.maxw-118 {
  max-width: 118px !important;
}

.maxw-119 {
  max-width: 119px !important;
}

.maxw-120 {
  max-width: 120px !important;
}

.maxw-121 {
  max-width: 121px !important;
}

.maxw-122 {
  max-width: 122px !important;
}

.maxw-123 {
  max-width: 123px !important;
}

.maxw-124 {
  max-width: 124px !important;
}

.maxw-125 {
  max-width: 125px !important;
}

.maxw-126 {
  max-width: 126px !important;
}

.maxw-127 {
  max-width: 127px !important;
}

.maxw-128 {
  max-width: 128px !important;
}

.maxw-129 {
  max-width: 129px !important;
}

.maxw-130 {
  max-width: 130px !important;
}

.maxw-131 {
  max-width: 131px !important;
}

.maxw-132 {
  max-width: 132px !important;
}

.maxw-133 {
  max-width: 133px !important;
}

.maxw-134 {
  max-width: 134px !important;
}

.maxw-135 {
  max-width: 135px !important;
}

.maxw-136 {
  max-width: 136px !important;
}

.maxw-137 {
  max-width: 137px !important;
}

.maxw-138 {
  max-width: 138px !important;
}

.maxw-139 {
  max-width: 139px !important;
}

.maxw-140 {
  max-width: 140px !important;
}

.maxw-141 {
  max-width: 141px !important;
}

.maxw-142 {
  max-width: 142px !important;
}

.maxw-143 {
  max-width: 143px !important;
}

.maxw-144 {
  max-width: 144px !important;
}

.maxw-145 {
  max-width: 145px !important;
}

.maxw-146 {
  max-width: 146px !important;
}

.maxw-147 {
  max-width: 147px !important;
}

.maxw-148 {
  max-width: 148px !important;
}

.maxw-149 {
  max-width: 149px !important;
}

.maxw-150 {
  max-width: 150px !important;
}

.maxw-151 {
  max-width: 151px !important;
}

.maxw-152 {
  max-width: 152px !important;
}

.maxw-153 {
  max-width: 153px !important;
}

.maxw-154 {
  max-width: 154px !important;
}

.maxw-155 {
  max-width: 155px !important;
}

.maxw-156 {
  max-width: 156px !important;
}

.maxw-157 {
  max-width: 157px !important;
}

.maxw-158 {
  max-width: 158px !important;
}

.maxw-159 {
  max-width: 159px !important;
}

.maxw-160 {
  max-width: 160px !important;
}

.maxw-161 {
  max-width: 161px !important;
}

.maxw-162 {
  max-width: 162px !important;
}

.maxw-163 {
  max-width: 163px !important;
}

.maxw-164 {
  max-width: 164px !important;
}

.maxw-165 {
  max-width: 165px !important;
}

.maxw-166 {
  max-width: 166px !important;
}

.maxw-167 {
  max-width: 167px !important;
}

.maxw-168 {
  max-width: 168px !important;
}

.maxw-169 {
  max-width: 169px !important;
}

.maxw-170 {
  max-width: 170px !important;
}

.maxw-171 {
  max-width: 171px !important;
}

.maxw-172 {
  max-width: 172px !important;
}

.maxw-173 {
  max-width: 173px !important;
}

.maxw-174 {
  max-width: 174px !important;
}

.maxw-175 {
  max-width: 175px !important;
}

.maxw-176 {
  max-width: 176px !important;
}

.maxw-177 {
  max-width: 177px !important;
}

.maxw-178 {
  max-width: 178px !important;
}

.maxw-179 {
  max-width: 179px !important;
}

.maxw-180 {
  max-width: 180px !important;
}

.maxw-181 {
  max-width: 181px !important;
}

.maxw-182 {
  max-width: 182px !important;
}

.maxw-183 {
  max-width: 183px !important;
}

.maxw-184 {
  max-width: 184px !important;
}

.maxw-185 {
  max-width: 185px !important;
}

.maxw-186 {
  max-width: 186px !important;
}

.maxw-187 {
  max-width: 187px !important;
}

.maxw-188 {
  max-width: 188px !important;
}

.maxw-189 {
  max-width: 189px !important;
}

.maxw-190 {
  max-width: 190px !important;
}

.maxw-191 {
  max-width: 191px !important;
}

.maxw-192 {
  max-width: 192px !important;
}

.maxw-193 {
  max-width: 193px !important;
}

.maxw-194 {
  max-width: 194px !important;
}

.maxw-195 {
  max-width: 195px !important;
}

.maxw-196 {
  max-width: 196px !important;
}

.maxw-197 {
  max-width: 197px !important;
}

.maxw-198 {
  max-width: 198px !important;
}

.maxw-199 {
  max-width: 199px !important;
}

.maxw-200 {
  max-width: 200px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MIN-WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

.minw-0 {
  min-width: 0px !important;
}

.minw-1 {
  min-width: 1px !important;
}

.minw-2 {
  min-width: 2px !important;
}

.minw-3 {
  min-width: 3px !important;
}

.minw-4 {
  min-width: 4px !important;
}

.minw-5 {
  min-width: 5px !important;
}

.minw-6 {
  min-width: 6px !important;
}

.minw-7 {
  min-width: 7px !important;
}

.minw-8 {
  min-width: 8px !important;
}

.minw-9 {
  min-width: 9px !important;
}

.minw-10 {
  min-width: 10px !important;
}

.minw-11 {
  min-width: 11px !important;
}

.minw-12 {
  min-width: 12px !important;
}

.minw-13 {
  min-width: 13px !important;
}

.minw-14 {
  min-width: 14px !important;
}

.minw-15 {
  min-width: 15px !important;
}

.minw-16 {
  min-width: 16px !important;
}

.minw-17 {
  min-width: 17px !important;
}

.minw-18 {
  min-width: 18px !important;
}

.minw-19 {
  min-width: 19px !important;
}

.minw-20 {
  min-width: 20px !important;
}

.minw-21 {
  min-width: 21px !important;
}

.minw-22 {
  min-width: 22px !important;
}

.minw-23 {
  min-width: 23px !important;
}

.minw-24 {
  min-width: 24px !important;
}

.minw-25 {
  min-width: 25px !important;
}

.minw-26 {
  min-width: 26px !important;
}

.minw-27 {
  min-width: 27px !important;
}

.minw-28 {
  min-width: 28px !important;
}

.minw-29 {
  min-width: 29px !important;
}

.minw-30 {
  min-width: 30px !important;
}

.minw-31 {
  min-width: 31px !important;
}

.minw-32 {
  min-width: 32px !important;
}

.minw-33 {
  min-width: 33px !important;
}

.minw-34 {
  min-width: 34px !important;
}

.minw-35 {
  min-width: 35px !important;
}

.minw-36 {
  min-width: 36px !important;
}

.minw-37 {
  min-width: 37px !important;
}

.minw-38 {
  min-width: 38px !important;
}

.minw-39 {
  min-width: 39px !important;
}

.minw-40 {
  min-width: 40px !important;
}

.minw-41 {
  min-width: 41px !important;
}

.minw-42 {
  min-width: 42px !important;
}

.minw-43 {
  min-width: 43px !important;
}

.minw-44 {
  min-width: 44px !important;
}

.minw-45 {
  min-width: 45px !important;
}

.minw-46 {
  min-width: 46px !important;
}

.minw-47 {
  min-width: 47px !important;
}

.minw-48 {
  min-width: 48px !important;
}

.minw-49 {
  min-width: 49px !important;
}

.minw-50 {
  min-width: 50px !important;
}

.minw-51 {
  min-width: 51px !important;
}

.minw-52 {
  min-width: 52px !important;
}

.minw-53 {
  min-width: 53px !important;
}

.minw-54 {
  min-width: 54px !important;
}

.minw-55 {
  min-width: 55px !important;
}

.minw-56 {
  min-width: 56px !important;
}

.minw-57 {
  min-width: 57px !important;
}

.minw-58 {
  min-width: 58px !important;
}

.minw-59 {
  min-width: 59px !important;
}

.minw-60 {
  min-width: 60px !important;
}

.minw-61 {
  min-width: 61px !important;
}

.minw-62 {
  min-width: 62px !important;
}

.minw-63 {
  min-width: 63px !important;
}

.minw-64 {
  min-width: 64px !important;
}

.minw-65 {
  min-width: 65px !important;
}

.minw-66 {
  min-width: 66px !important;
}

.minw-67 {
  min-width: 67px !important;
}

.minw-68 {
  min-width: 68px !important;
}

.minw-69 {
  min-width: 69px !important;
}

.minw-70 {
  min-width: 70px !important;
}

.minw-71 {
  min-width: 71px !important;
}

.minw-72 {
  min-width: 72px !important;
}

.minw-73 {
  min-width: 73px !important;
}

.minw-74 {
  min-width: 74px !important;
}

.minw-75 {
  min-width: 75px !important;
}

.minw-76 {
  min-width: 76px !important;
}

.minw-77 {
  min-width: 77px !important;
}

.minw-78 {
  min-width: 78px !important;
}

.minw-79 {
  min-width: 79px !important;
}

.minw-80 {
  min-width: 80px !important;
}

.minw-81 {
  min-width: 81px !important;
}

.minw-82 {
  min-width: 82px !important;
}

.minw-83 {
  min-width: 83px !important;
}

.minw-84 {
  min-width: 84px !important;
}

.minw-85 {
  min-width: 85px !important;
}

.minw-86 {
  min-width: 86px !important;
}

.minw-87 {
  min-width: 87px !important;
}

.minw-88 {
  min-width: 88px !important;
}

.minw-89 {
  min-width: 89px !important;
}

.minw-90 {
  min-width: 90px !important;
}

.minw-91 {
  min-width: 91px !important;
}

.minw-92 {
  min-width: 92px !important;
}

.minw-93 {
  min-width: 93px !important;
}

.minw-94 {
  min-width: 94px !important;
}

.minw-95 {
  min-width: 95px !important;
}

.minw-96 {
  min-width: 96px !important;
}

.minw-97 {
  min-width: 97px !important;
}

.minw-98 {
  min-width: 98px !important;
}

.minw-99 {
  min-width: 99px !important;
}

.minw-100 {
  min-width: 100px !important;
}

.minw-101 {
  min-width: 101px !important;
}

.minw-102 {
  min-width: 102px !important;
}

.minw-103 {
  min-width: 103px !important;
}

.minw-104 {
  min-width: 104px !important;
}

.minw-105 {
  min-width: 105px !important;
}

.minw-106 {
  min-width: 106px !important;
}

.minw-107 {
  min-width: 107px !important;
}

.minw-108 {
  min-width: 108px !important;
}

.minw-109 {
  min-width: 109px !important;
}

.minw-110 {
  min-width: 110px !important;
}

.minw-111 {
  min-width: 111px !important;
}

.minw-112 {
  min-width: 112px !important;
}

.minw-113 {
  min-width: 113px !important;
}

.minw-114 {
  min-width: 114px !important;
}

.minw-115 {
  min-width: 115px !important;
}

.minw-116 {
  min-width: 116px !important;
}

.minw-117 {
  min-width: 117px !important;
}

.minw-118 {
  min-width: 118px !important;
}

.minw-119 {
  min-width: 119px !important;
}

.minw-120 {
  min-width: 120px !important;
}

.minw-121 {
  min-width: 121px !important;
}

.minw-122 {
  min-width: 122px !important;
}

.minw-123 {
  min-width: 123px !important;
}

.minw-124 {
  min-width: 124px !important;
}

.minw-125 {
  min-width: 125px !important;
}

.minw-126 {
  min-width: 126px !important;
}

.minw-127 {
  min-width: 127px !important;
}

.minw-128 {
  min-width: 128px !important;
}

.minw-129 {
  min-width: 129px !important;
}

.minw-130 {
  min-width: 130px !important;
}

.minw-131 {
  min-width: 131px !important;
}

.minw-132 {
  min-width: 132px !important;
}

.minw-133 {
  min-width: 133px !important;
}

.minw-134 {
  min-width: 134px !important;
}

.minw-135 {
  min-width: 135px !important;
}

.minw-136 {
  min-width: 136px !important;
}

.minw-137 {
  min-width: 137px !important;
}

.minw-138 {
  min-width: 138px !important;
}

.minw-139 {
  min-width: 139px !important;
}

.minw-140 {
  min-width: 140px !important;
}

.minw-141 {
  min-width: 141px !important;
}

.minw-142 {
  min-width: 142px !important;
}

.minw-143 {
  min-width: 143px !important;
}

.minw-144 {
  min-width: 144px !important;
}

.minw-145 {
  min-width: 145px !important;
}

.minw-146 {
  min-width: 146px !important;
}

.minw-147 {
  min-width: 147px !important;
}

.minw-148 {
  min-width: 148px !important;
}

.minw-149 {
  min-width: 149px !important;
}

.minw-150 {
  min-width: 150px !important;
}

.minw-151 {
  min-width: 151px !important;
}

.minw-152 {
  min-width: 152px !important;
}

.minw-153 {
  min-width: 153px !important;
}

.minw-154 {
  min-width: 154px !important;
}

.minw-155 {
  min-width: 155px !important;
}

.minw-156 {
  min-width: 156px !important;
}

.minw-157 {
  min-width: 157px !important;
}

.minw-158 {
  min-width: 158px !important;
}

.minw-159 {
  min-width: 159px !important;
}

.minw-160 {
  min-width: 160px !important;
}

.minw-161 {
  min-width: 161px !important;
}

.minw-162 {
  min-width: 162px !important;
}

.minw-163 {
  min-width: 163px !important;
}

.minw-164 {
  min-width: 164px !important;
}

.minw-165 {
  min-width: 165px !important;
}

.minw-166 {
  min-width: 166px !important;
}

.minw-167 {
  min-width: 167px !important;
}

.minw-168 {
  min-width: 168px !important;
}

.minw-169 {
  min-width: 169px !important;
}

.minw-170 {
  min-width: 170px !important;
}

.minw-171 {
  min-width: 171px !important;
}

.minw-172 {
  min-width: 172px !important;
}

.minw-173 {
  min-width: 173px !important;
}

.minw-174 {
  min-width: 174px !important;
}

.minw-175 {
  min-width: 175px !important;
}

.minw-176 {
  min-width: 176px !important;
}

.minw-177 {
  min-width: 177px !important;
}

.minw-178 {
  min-width: 178px !important;
}

.minw-179 {
  min-width: 179px !important;
}

.minw-180 {
  min-width: 180px !important;
}

.minw-181 {
  min-width: 181px !important;
}

.minw-182 {
  min-width: 182px !important;
}

.minw-183 {
  min-width: 183px !important;
}

.minw-184 {
  min-width: 184px !important;
}

.minw-185 {
  min-width: 185px !important;
}

.minw-186 {
  min-width: 186px !important;
}

.minw-187 {
  min-width: 187px !important;
}

.minw-188 {
  min-width: 188px !important;
}

.minw-189 {
  min-width: 189px !important;
}

.minw-190 {
  min-width: 190px !important;
}

.minw-191 {
  min-width: 191px !important;
}

.minw-192 {
  min-width: 192px !important;
}

.minw-193 {
  min-width: 193px !important;
}

.minw-194 {
  min-width: 194px !important;
}

.minw-195 {
  min-width: 195px !important;
}

.minw-196 {
  min-width: 196px !important;
}

.minw-197 {
  min-width: 197px !important;
}

.minw-198 {
  min-width: 198px !important;
}

.minw-199 {
  min-width: 199px !important;
}

.minw-200 {
  min-width: 200px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* HEIGHT */
/* ------------------------------------- */
/* ------------------------------------- */

.h-0 {
  height: 0% !important;
}

.h-1 {
  height: 1% !important;
}

.h-2 {
  height: 2% !important;
}

.h-3 {
  height: 3% !important;
}

.h-4 {
  height: 4% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-7 {
  height: 7% !important;
}

.h-8 {
  height: 8% !important;
}

.h-9 {
  height: 9% !important;
}

.h-10 {
  height: 10% !important;
}

.h-11 {
  height: 11% !important;
}

.h-12 {
  height: 12% !important;
}

.h-13 {
  height: 13% !important;
}

.h-14 {
  height: 14% !important;
}

.h-15 {
  height: 15% !important;
}

.h-16 {
  height: 16% !important;
}

.h-17 {
  height: 17% !important;
}

.h-18 {
  height: 18% !important;
}

.h-19 {
  height: 19% !important;
}

.h-20 {
  height: 20% !important;
}

.h-21 {
  height: 21% !important;
}

.h-22 {
  height: 22% !important;
}

.h-23 {
  height: 23% !important;
}

.h-24 {
  height: 24% !important;
}

.h-25 {
  height: 25% !important;
}

.h-26 {
  height: 26% !important;
}

.h-27 {
  height: 27% !important;
}

.h-28 {
  height: 28% !important;
}

.h-29 {
  height: 29% !important;
}

.h-30 {
  height: 30% !important;
}

.h-31 {
  height: 31% !important;
}

.h-32 {
  height: 32% !important;
}

.h-33 {
  height: 33% !important;
}

.h-34 {
  height: 34% !important;
}

.h-35 {
  height: 35% !important;
}

.h-36 {
  height: 36% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-41 {
  height: 41% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 43% !important;
}

.h-44 {
  height: 44% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-50 {
  height: 50% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-54 {
  height: 54% !important;
}

.h-55 {
  height: 55% !important;
}

.h-56 {
  height: 56% !important;
}

.h-57 {
  height: 57% !important;
}

.h-58 {
  height: 58% !important;
}

.h-59 {
  height: 59% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61 {
  height: 61% !important;
}

.h-62 {
  height: 62% !important;
}

.h-63 {
  height: 63% !important;
}

.h-64 {
  height: 64% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66% !important;
}

.h-67 {
  height: 67% !important;
}

.h-68 {
  height: 68% !important;
}

.h-69 {
  height: 69% !important;
}

.h-70 {
  height: 70% !important;
}

.h-71 {
  height: 71% !important;
}

.h-72 {
  height: 72% !important;
}

.h-73 {
  height: 73% !important;
}

.h-74 {
  height: 74% !important;
}

.h-75 {
  height: 75% !important;
}

.h-76 {
  height: 76% !important;
}

.h-77 {
  height: 77% !important;
}

.h-78 {
  height: 78% !important;
}

.h-79 {
  height: 79% !important;
}

.h-80 {
  height: 80% !important;
}

.h-81 {
  height: 81% !important;
}

.h-82 {
  height: 82% !important;
}

.h-83 {
  height: 83% !important;
}

.h-84 {
  height: 84% !important;
}

.h-85 {
  height: 85% !important;
}

.h-86 {
  height: 86% !important;
}

.h-87 {
  height: 87% !important;
}

.h-88 {
  height: 88% !important;
}

.h-89 {
  height: 89% !important;
}

.h-90 {
  height: 90% !important;
}

.h-91 {
  height: 91% !important;
}

.h-92 {
  height: 92% !important;
}

.h-93 {
  height: 93% !important;
}

.h-94 {
  height: 94% !important;
}

.h-95 {
  height: 95% !important;
}

.h-96 {
  height: 96% !important;
}

.h-97 {
  height: 97% !important;
}

.h-98 {
  height: 98% !important;
}

.h-99 {
  height: 99% !important;
}

.h-100 {
  height: 100% !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MIN-HEIGHT */
/* ------------------------------------- */
/* ------------------------------------- */

.minh-0 {
  min-height: 0px !important;
}

.minh-1 {
  min-height: 1px !important;
}

.minh-2 {
  min-height: 2px !important;
}

.minh-3 {
  min-height: 3px !important;
}

.minh-4 {
  min-height: 4px !important;
}

.minh-5 {
  min-height: 5px !important;
}

.minh-6 {
  min-height: 6px !important;
}

.minh-7 {
  min-height: 7px !important;
}

.minh-8 {
  min-height: 8px !important;
}

.minh-9 {
  min-height: 9px !important;
}

.minh-10 {
  min-height: 10px !important;
}

.minh-11 {
  min-height: 11px !important;
}

.minh-12 {
  min-height: 12px !important;
}

.minh-13 {
  min-height: 13px !important;
}

.minh-14 {
  min-height: 14px !important;
}

.minh-15 {
  min-height: 15px !important;
}

.minh-16 {
  min-height: 16px !important;
}

.minh-17 {
  min-height: 17px !important;
}

.minh-18 {
  min-height: 18px !important;
}

.minh-19 {
  min-height: 19px !important;
}

.minh-20 {
  min-height: 20px !important;
}

.minh-21 {
  min-height: 21px !important;
}

.minh-22 {
  min-height: 22px !important;
}

.minh-23 {
  min-height: 23px !important;
}

.minh-24 {
  min-height: 24px !important;
}

.minh-25 {
  min-height: 25px !important;
}

.minh-26 {
  min-height: 26px !important;
}

.minh-27 {
  min-height: 27px !important;
}

.minh-28 {
  min-height: 28px !important;
}

.minh-29 {
  min-height: 29px !important;
}

.minh-30 {
  min-height: 30px !important;
}

.minh-31 {
  min-height: 31px !important;
}

.minh-32 {
  min-height: 32px !important;
}

.minh-33 {
  min-height: 33px !important;
}

.minh-34 {
  min-height: 34px !important;
}

.minh-35 {
  min-height: 35px !important;
}

.minh-36 {
  min-height: 36px !important;
}

.minh-37 {
  min-height: 37px !important;
}

.minh-38 {
  min-height: 38px !important;
}

.minh-39 {
  min-height: 39px !important;
}

.minh-40 {
  min-height: 40px !important;
}

.minh-41 {
  min-height: 41px !important;
}

.minh-42 {
  min-height: 42px !important;
}

.minh-43 {
  min-height: 43px !important;
}

.minh-44 {
  min-height: 44px !important;
}

.minh-45 {
  min-height: 45px !important;
}

.minh-46 {
  min-height: 46px !important;
}

.minh-47 {
  min-height: 47px !important;
}

.minh-48 {
  min-height: 48px !important;
}

.minh-49 {
  min-height: 49px !important;
}

.minh-50 {
  min-height: 50px !important;
}

.minh-51 {
  min-height: 51px !important;
}

.minh-52 {
  min-height: 52px !important;
}

.minh-53 {
  min-height: 53px !important;
}

.minh-54 {
  min-height: 54px !important;
}

.minh-55 {
  min-height: 55px !important;
}

.minh-56 {
  min-height: 56px !important;
}

.minh-57 {
  min-height: 57px !important;
}

.minh-58 {
  min-height: 58px !important;
}

.minh-59 {
  min-height: 59px !important;
}

.minh-60 {
  min-height: 60px !important;
}

.minh-61 {
  min-height: 61px !important;
}

.minh-62 {
  min-height: 62px !important;
}

.minh-63 {
  min-height: 63px !important;
}

.minh-64 {
  min-height: 64px !important;
}

.minh-65 {
  min-height: 65px !important;
}

.minh-66 {
  min-height: 66px !important;
}

.minh-67 {
  min-height: 67px !important;
}

.minh-68 {
  min-height: 68px !important;
}

.minh-69 {
  min-height: 69px !important;
}

.minh-70 {
  min-height: 70px !important;
}

.minh-71 {
  min-height: 71px !important;
}

.minh-72 {
  min-height: 72px !important;
}

.minh-73 {
  min-height: 73px !important;
}

.minh-74 {
  min-height: 74px !important;
}

.minh-75 {
  min-height: 75px !important;
}

.minh-76 {
  min-height: 76px !important;
}

.minh-77 {
  min-height: 77px !important;
}

.minh-78 {
  min-height: 78px !important;
}

.minh-79 {
  min-height: 79px !important;
}

.minh-80 {
  min-height: 80px !important;
}

.minh-81 {
  min-height: 81px !important;
}

.minh-82 {
  min-height: 82px !important;
}

.minh-83 {
  min-height: 83px !important;
}

.minh-84 {
  min-height: 84px !important;
}

.minh-85 {
  min-height: 85px !important;
}

.minh-86 {
  min-height: 86px !important;
}

.minh-87 {
  min-height: 87px !important;
}

.minh-88 {
  min-height: 88px !important;
}

.minh-89 {
  min-height: 89px !important;
}

.minh-90 {
  min-height: 90px !important;
}

.minh-91 {
  min-height: 91px !important;
}

.minh-92 {
  min-height: 92px !important;
}

.minh-93 {
  min-height: 93px !important;
}

.minh-94 {
  min-height: 94px !important;
}

.minh-95 {
  min-height: 95px !important;
}

.minh-96 {
  min-height: 96px !important;
}

.minh-97 {
  min-height: 97px !important;
}

.minh-98 {
  min-height: 98px !important;
}

.minh-99 {
  min-height: 99px !important;
}

.minh-100 {
  min-height: 100px !important;
}

.minh-101 {
  min-height: 101px !important;
}

.minh-102 {
  min-height: 102px !important;
}

.minh-103 {
  min-height: 103px !important;
}

.minh-104 {
  min-height: 104px !important;
}

.minh-105 {
  min-height: 105px !important;
}

.minh-106 {
  min-height: 106px !important;
}

.minh-107 {
  min-height: 107px !important;
}

.minh-108 {
  min-height: 108px !important;
}

.minh-109 {
  min-height: 109px !important;
}

.minh-110 {
  min-height: 110px !important;
}

.minh-111 {
  min-height: 111px !important;
}

.minh-112 {
  min-height: 112px !important;
}

.minh-113 {
  min-height: 113px !important;
}

.minh-114 {
  min-height: 114px !important;
}

.minh-115 {
  min-height: 115px !important;
}

.minh-116 {
  min-height: 116px !important;
}

.minh-117 {
  min-height: 117px !important;
}

.minh-118 {
  min-height: 118px !important;
}

.minh-119 {
  min-height: 119px !important;
}

.minh-120 {
  min-height: 120px !important;
}

.minh-121 {
  min-height: 121px !important;
}

.minh-122 {
  min-height: 122px !important;
}

.minh-123 {
  min-height: 123px !important;
}

.minh-124 {
  min-height: 124px !important;
}

.minh-125 {
  min-height: 125px !important;
}

.minh-126 {
  min-height: 126px !important;
}

.minh-127 {
  min-height: 127px !important;
}

.minh-128 {
  min-height: 128px !important;
}

.minh-129 {
  min-height: 129px !important;
}

.minh-130 {
  min-height: 130px !important;
}

.minh-131 {
  min-height: 131px !important;
}

.minh-132 {
  min-height: 132px !important;
}

.minh-133 {
  min-height: 133px !important;
}

.minh-134 {
  min-height: 134px !important;
}

.minh-135 {
  min-height: 135px !important;
}

.minh-136 {
  min-height: 136px !important;
}

.minh-137 {
  min-height: 137px !important;
}

.minh-138 {
  min-height: 138px !important;
}

.minh-139 {
  min-height: 139px !important;
}

.minh-140 {
  min-height: 140px !important;
}

.minh-141 {
  min-height: 141px !important;
}

.minh-142 {
  min-height: 142px !important;
}

.minh-143 {
  min-height: 143px !important;
}

.minh-144 {
  min-height: 144px !important;
}

.minh-145 {
  min-height: 145px !important;
}

.minh-146 {
  min-height: 146px !important;
}

.minh-147 {
  min-height: 147px !important;
}

.minh-148 {
  min-height: 148px !important;
}

.minh-149 {
  min-height: 149px !important;
}

.minh-150 {
  min-height: 150px !important;
}

.minh-151 {
  min-height: 151px !important;
}

.minh-152 {
  min-height: 152px !important;
}

.minh-153 {
  min-height: 153px !important;
}

.minh-154 {
  min-height: 154px !important;
}

.minh-155 {
  min-height: 155px !important;
}

.minh-156 {
  min-height: 156px !important;
}

.minh-157 {
  min-height: 157px !important;
}

.minh-158 {
  min-height: 158px !important;
}

.minh-159 {
  min-height: 159px !important;
}

.minh-160 {
  min-height: 160px !important;
}

.minh-161 {
  min-height: 161px !important;
}

.minh-162 {
  min-height: 162px !important;
}

.minh-163 {
  min-height: 163px !important;
}

.minh-164 {
  min-height: 164px !important;
}

.minh-165 {
  min-height: 165px !important;
}

.minh-166 {
  min-height: 166px !important;
}

.minh-167 {
  min-height: 167px !important;
}

.minh-168 {
  min-height: 168px !important;
}

.minh-169 {
  min-height: 169px !important;
}

.minh-170 {
  min-height: 170px !important;
}

.minh-171 {
  min-height: 171px !important;
}

.minh-172 {
  min-height: 172px !important;
}

.minh-173 {
  min-height: 173px !important;
}

.minh-174 {
  min-height: 174px !important;
}

.minh-175 {
  min-height: 175px !important;
}

.minh-176 {
  min-height: 176px !important;
}

.minh-177 {
  min-height: 177px !important;
}

.minh-178 {
  min-height: 178px !important;
}

.minh-179 {
  min-height: 179px !important;
}

.minh-180 {
  min-height: 180px !important;
}

.minh-181 {
  min-height: 181px !important;
}

.minh-182 {
  min-height: 182px !important;
}

.minh-183 {
  min-height: 183px !important;
}

.minh-184 {
  min-height: 184px !important;
}

.minh-185 {
  min-height: 185px !important;
}

.minh-186 {
  min-height: 186px !important;
}

.minh-187 {
  min-height: 187px !important;
}

.minh-188 {
  min-height: 188px !important;
}

.minh-189 {
  min-height: 189px !important;
}

.minh-190 {
  min-height: 190px !important;
}

.minh-191 {
  min-height: 191px !important;
}

.minh-192 {
  min-height: 192px !important;
}

.minh-193 {
  min-height: 193px !important;
}

.minh-194 {
  min-height: 194px !important;
}

.minh-195 {
  min-height: 195px !important;
}

.minh-196 {
  min-height: 196px !important;
}

.minh-197 {
  min-height: 197px !important;
}

.minh-198 {
  min-height: 198px !important;
}

.minh-199 {
  min-height: 199px !important;
}

.minh-200 {
  min-height: 200px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MAX-HEIGHT */
/* ------------------------------------- */
/* ------------------------------------- */

.maxh-0 {
  max-height: 0px !important;
}

.maxh-1 {
  max-height: 1px !important;
}

.maxh-2 {
  max-height: 2px !important;
}

.maxh-3 {
  max-height: 3px !important;
}

.maxh-4 {
  max-height: 4px !important;
}

.maxh-5 {
  max-height: 5px !important;
}

.maxh-6 {
  max-height: 6px !important;
}

.maxh-7 {
  max-height: 7px !important;
}

.maxh-8 {
  max-height: 8px !important;
}

.maxh-9 {
  max-height: 9px !important;
}

.maxh-10 {
  max-height: 10px !important;
}

.maxh-11 {
  max-height: 11px !important;
}

.maxh-12 {
  max-height: 12px !important;
}

.maxh-13 {
  max-height: 13px !important;
}

.maxh-14 {
  max-height: 14px !important;
}

.maxh-15 {
  max-height: 15px !important;
}

.maxh-16 {
  max-height: 16px !important;
}

.maxh-17 {
  max-height: 17px !important;
}

.maxh-18 {
  max-height: 18px !important;
}

.maxh-19 {
  max-height: 19px !important;
}

.maxh-20 {
  max-height: 20px !important;
}

.maxh-21 {
  max-height: 21px !important;
}

.maxh-22 {
  max-height: 22px !important;
}

.maxh-23 {
  max-height: 23px !important;
}

.maxh-24 {
  max-height: 24px !important;
}

.maxh-25 {
  max-height: 25px !important;
}

.maxh-26 {
  max-height: 26px !important;
}

.maxh-27 {
  max-height: 27px !important;
}

.maxh-28 {
  max-height: 28px !important;
}

.maxh-29 {
  max-height: 29px !important;
}

.maxh-30 {
  max-height: 30px !important;
}

.maxh-31 {
  max-height: 31px !important;
}

.maxh-32 {
  max-height: 32px !important;
}

.maxh-33 {
  max-height: 33px !important;
}

.maxh-34 {
  max-height: 34px !important;
}

.maxh-35 {
  max-height: 35px !important;
}

.maxh-36 {
  max-height: 36px !important;
}

.maxh-37 {
  max-height: 37px !important;
}

.maxh-38 {
  max-height: 38px !important;
}

.maxh-39 {
  max-height: 39px !important;
}

.maxh-40 {
  max-height: 40px !important;
}

.maxh-41 {
  max-height: 41px !important;
}

.maxh-42 {
  max-height: 42px !important;
}

.maxh-43 {
  max-height: 43px !important;
}

.maxh-44 {
  max-height: 44px !important;
}

.maxh-45 {
  max-height: 45px !important;
}

.maxh-46 {
  max-height: 46px !important;
}

.maxh-47 {
  max-height: 47px !important;
}

.maxh-48 {
  max-height: 48px !important;
}

.maxh-49 {
  max-height: 49px !important;
}

.maxh-50 {
  max-height: 50px !important;
}

.maxh-51 {
  max-height: 51px !important;
}

.maxh-52 {
  max-height: 52px !important;
}

.maxh-53 {
  max-height: 53px !important;
}

.maxh-54 {
  max-height: 54px !important;
}

.maxh-55 {
  max-height: 55px !important;
}

.maxh-56 {
  max-height: 56px !important;
}

.maxh-57 {
  max-height: 57px !important;
}

.maxh-58 {
  max-height: 58px !important;
}

.maxh-59 {
  max-height: 59px !important;
}

.maxh-60 {
  max-height: 60px !important;
}

.maxh-61 {
  max-height: 61px !important;
}

.maxh-62 {
  max-height: 62px !important;
}

.maxh-63 {
  max-height: 63px !important;
}

.maxh-64 {
  max-height: 64px !important;
}

.maxh-65 {
  max-height: 65px !important;
}

.maxh-66 {
  max-height: 66px !important;
}

.maxh-67 {
  max-height: 67px !important;
}

.maxh-68 {
  max-height: 68px !important;
}

.maxh-69 {
  max-height: 69px !important;
}

.maxh-70 {
  max-height: 70px !important;
}

.maxh-71 {
  max-height: 71px !important;
}

.maxh-72 {
  max-height: 72px !important;
}

.maxh-73 {
  max-height: 73px !important;
}

.maxh-74 {
  max-height: 74px !important;
}

.maxh-75 {
  max-height: 75px !important;
}

.maxh-76 {
  max-height: 76px !important;
}

.maxh-77 {
  max-height: 77px !important;
}

.maxh-78 {
  max-height: 78px !important;
}

.maxh-79 {
  max-height: 79px !important;
}

.maxh-80 {
  max-height: 80px !important;
}

.maxh-81 {
  max-height: 81px !important;
}

.maxh-82 {
  max-height: 82px !important;
}

.maxh-83 {
  max-height: 83px !important;
}

.maxh-84 {
  max-height: 84px !important;
}

.maxh-85 {
  max-height: 85px !important;
}

.maxh-86 {
  max-height: 86px !important;
}

.maxh-87 {
  max-height: 87px !important;
}

.maxh-88 {
  max-height: 88px !important;
}

.maxh-89 {
  max-height: 89px !important;
}

.maxh-90 {
  max-height: 90px !important;
}

.maxh-91 {
  max-height: 91px !important;
}

.maxh-92 {
  max-height: 92px !important;
}

.maxh-93 {
  max-height: 93px !important;
}

.maxh-94 {
  max-height: 94px !important;
}

.maxh-95 {
  max-height: 95px !important;
}

.maxh-96 {
  max-height: 96px !important;
}

.maxh-97 {
  max-height: 97px !important;
}

.maxh-98 {
  max-height: 98px !important;
}

.maxh-99 {
  max-height: 99px !important;
}

.maxh-100 {
  max-height: 100px !important;
}

.maxh-101 {
  max-height: 101px !important;
}

.maxh-102 {
  max-height: 102px !important;
}

.maxh-103 {
  max-height: 103px !important;
}

.maxh-104 {
  max-height: 104px !important;
}

.maxh-105 {
  max-height: 105px !important;
}

.maxh-106 {
  max-height: 106px !important;
}

.maxh-107 {
  max-height: 107px !important;
}

.maxh-108 {
  max-height: 108px !important;
}

.maxh-109 {
  max-height: 109px !important;
}

.maxh-110 {
  max-height: 110px !important;
}

.maxh-111 {
  max-height: 111px !important;
}

.maxh-112 {
  max-height: 112px !important;
}

.maxh-113 {
  max-height: 113px !important;
}

.maxh-114 {
  max-height: 114px !important;
}

.maxh-115 {
  max-height: 115px !important;
}

.maxh-116 {
  max-height: 116px !important;
}

.maxh-117 {
  max-height: 117px !important;
}

.maxh-118 {
  max-height: 118px !important;
}

.maxh-119 {
  max-height: 119px !important;
}

.maxh-120 {
  max-height: 120px !important;
}

.maxh-121 {
  max-height: 121px !important;
}

.maxh-122 {
  max-height: 122px !important;
}

.maxh-123 {
  max-height: 123px !important;
}

.maxh-124 {
  max-height: 124px !important;
}

.maxh-125 {
  max-height: 125px !important;
}

.maxh-126 {
  max-height: 126px !important;
}

.maxh-127 {
  max-height: 127px !important;
}

.maxh-128 {
  max-height: 128px !important;
}

.maxh-129 {
  max-height: 129px !important;
}

.maxh-130 {
  max-height: 130px !important;
}

.maxh-131 {
  max-height: 131px !important;
}

.maxh-132 {
  max-height: 132px !important;
}

.maxh-133 {
  max-height: 133px !important;
}

.maxh-134 {
  max-height: 134px !important;
}

.maxh-135 {
  max-height: 135px !important;
}

.maxh-136 {
  max-height: 136px !important;
}

.maxh-137 {
  max-height: 137px !important;
}

.maxh-138 {
  max-height: 138px !important;
}

.maxh-139 {
  max-height: 139px !important;
}

.maxh-140 {
  max-height: 140px !important;
}

.maxh-141 {
  max-height: 141px !important;
}

.maxh-142 {
  max-height: 142px !important;
}

.maxh-143 {
  max-height: 143px !important;
}

.maxh-144 {
  max-height: 144px !important;
}

.maxh-145 {
  max-height: 145px !important;
}

.maxh-146 {
  max-height: 146px !important;
}

.maxh-147 {
  max-height: 147px !important;
}

.maxh-148 {
  max-height: 148px !important;
}

.maxh-149 {
  max-height: 149px !important;
}

.maxh-150 {
  max-height: 150px !important;
}

.maxh-151 {
  max-height: 151px !important;
}

.maxh-152 {
  max-height: 152px !important;
}

.maxh-153 {
  max-height: 153px !important;
}

.maxh-154 {
  max-height: 154px !important;
}

.maxh-155 {
  max-height: 155px !important;
}

.maxh-156 {
  max-height: 156px !important;
}

.maxh-157 {
  max-height: 157px !important;
}

.maxh-158 {
  max-height: 158px !important;
}

.maxh-159 {
  max-height: 159px !important;
}

.maxh-160 {
  max-height: 160px !important;
}

.maxh-161 {
  max-height: 161px !important;
}

.maxh-162 {
  max-height: 162px !important;
}

.maxh-163 {
  max-height: 163px !important;
}

.maxh-164 {
  max-height: 164px !important;
}

.maxh-165 {
  max-height: 165px !important;
}

.maxh-166 {
  max-height: 166px !important;
}

.maxh-167 {
  max-height: 167px !important;
}

.maxh-168 {
  max-height: 168px !important;
}

.maxh-169 {
  max-height: 169px !important;
}

.maxh-170 {
  max-height: 170px !important;
}

.maxh-171 {
  max-height: 171px !important;
}

.maxh-172 {
  max-height: 172px !important;
}

.maxh-173 {
  max-height: 173px !important;
}

.maxh-174 {
  max-height: 174px !important;
}

.maxh-175 {
  max-height: 175px !important;
}

.maxh-176 {
  max-height: 176px !important;
}

.maxh-177 {
  max-height: 177px !important;
}

.maxh-178 {
  max-height: 178px !important;
}

.maxh-179 {
  max-height: 179px !important;
}

.maxh-180 {
  max-height: 180px !important;
}

.maxh-181 {
  max-height: 181px !important;
}

.maxh-182 {
  max-height: 182px !important;
}

.maxh-183 {
  max-height: 183px !important;
}

.maxh-184 {
  max-height: 184px !important;
}

.maxh-185 {
  max-height: 185px !important;
}

.maxh-186 {
  max-height: 186px !important;
}

.maxh-187 {
  max-height: 187px !important;
}

.maxh-188 {
  max-height: 188px !important;
}

.maxh-189 {
  max-height: 189px !important;
}

.maxh-190 {
  max-height: 190px !important;
}

.maxh-191 {
  max-height: 191px !important;
}

.maxh-192 {
  max-height: 192px !important;
}

.maxh-193 {
  max-height: 193px !important;
}

.maxh-194 {
  max-height: 194px !important;
}

.maxh-195 {
  max-height: 195px !important;
}

.maxh-196 {
  max-height: 196px !important;
}

.maxh-197 {
  max-height: 197px !important;
}

.maxh-198 {
  max-height: 198px !important;
}

.maxh-199 {
  max-height: 199px !important;
}

.maxh-200 {
  max-height: 200px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* PADDINGS */
/* ------------------------------------- */
/* ------------------------------------- */

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 1px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-3 {
  padding: 3px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-7 {
  padding: 7px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-9 {
  padding: 9px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-11 {
  padding: 11px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-13 {
  padding: 13px !important;
}

.p-14 {
  padding: 14px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-17 {
  padding: 17px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-19 {
  padding: 19px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-21 {
  padding: 21px !important;
}

.p-22 {
  padding: 22px !important;
}

.p-23 {
  padding: 23px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-26 {
  padding: 26px !important;
}

.p-27 {
  padding: 27px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-29 {
  padding: 29px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-31 {
  padding: 31px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-33 {
  padding: 33px !important;
}

.p-34 {
  padding: 34px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-37 {
  padding: 37px !important;
}

.p-38 {
  padding: 38px !important;
}

.p-39 {
  padding: 39px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-41 {
  padding: 41px !important;
}

.p-42 {
  padding: 42px !important;
}

.p-43 {
  padding: 43px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-46 {
  padding: 46px !important;
}

.p-47 {
  padding: 47px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-49 {
  padding: 49px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-51 {
  padding: 51px !important;
}

.p-52 {
  padding: 52px !important;
}

.p-53 {
  padding: 53px !important;
}

.p-54 {
  padding: 54px !important;
}

.p-55 {
  padding: 55px !important;
}

.p-56 {
  padding: 56px !important;
}

.p-57 {
  padding: 57px !important;
}

.p-58 {
  padding: 58px !important;
}

.p-59 {
  padding: 59px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-61 {
  padding: 61px !important;
}

.p-62 {
  padding: 62px !important;
}

.p-63 {
  padding: 63px !important;
}

.p-64 {
  padding: 64px !important;
}

.p-65 {
  padding: 65px !important;
}

.p-66 {
  padding: 66px !important;
}

.p-67 {
  padding: 67px !important;
}

.p-68 {
  padding: 68px !important;
}

.p-69 {
  padding: 69px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-71 {
  padding: 71px !important;
}

.p-72 {
  padding: 72px !important;
}

.p-73 {
  padding: 73px !important;
}

.p-74 {
  padding: 74px !important;
}

.p-75 {
  padding: 75px !important;
}

.p-76 {
  padding: 76px !important;
}

.p-77 {
  padding: 77px !important;
}

.p-78 {
  padding: 78px !important;
}

.p-79 {
  padding: 79px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-81 {
  padding: 81px !important;
}

.p-82 {
  padding: 82px !important;
}

.p-83 {
  padding: 83px !important;
}

.p-84 {
  padding: 84px !important;
}

.p-85 {
  padding: 85px !important;
}

.p-86 {
  padding: 86px !important;
}

.p-87 {
  padding: 87px !important;
}

.p-88 {
  padding: 88px !important;
}

.p-89 {
  padding: 89px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-91 {
  padding: 91px !important;
}

.p-92 {
  padding: 92px !important;
}

.p-93 {
  padding: 93px !important;
}

.p-94 {
  padding: 94px !important;
}

.p-95 {
  padding: 95px !important;
}

.p-96 {
  padding: 96px !important;
}

.p-97 {
  padding: 97px !important;
}

.p-98 {
  padding: 98px !important;
}

.p-99 {
  padding: 99px !important;
}

.p-100 {
  padding: 100px !important;
}

/* ----------- */
/* PADDING-TOP */
/* ----------- */

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-9 {
  padding-top: 9px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pt-19 {
  padding-top: 19px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-21 {
  padding-top: 21px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.pt-23 {
  padding-top: 23px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.pt-27 {
  padding-top: 27px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pt-29 {
  padding-top: 29px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-31 {
  padding-top: 31px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pt-37 {
  padding-top: 37px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.pt-39 {
  padding-top: 39px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.pt-43 {
  padding-top: 43px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pt-49 {
  padding-top: 49px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-51 {
  padding-top: 51px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pt-53 {
  padding-top: 53px !important;
}

.pt-54 {
  padding-top: 54px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pt-57 {
  padding-top: 57px !important;
}

.pt-58 {
  padding-top: 58px !important;
}

.pt-59 {
  padding-top: 59px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-61 {
  padding-top: 61px !important;
}

.pt-62 {
  padding-top: 62px !important;
}

.pt-63 {
  padding-top: 63px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-66 {
  padding-top: 66px !important;
}

.pt-67 {
  padding-top: 67px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pt-69 {
  padding-top: 69px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-71 {
  padding-top: 71px !important;
}

.pt-72 {
  padding-top: 72px !important;
}

.pt-73 {
  padding-top: 73px !important;
}

.pt-74 {
  padding-top: 74px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-76 {
  padding-top: 76px !important;
}

.pt-77 {
  padding-top: 77px !important;
}

.pt-78 {
  padding-top: 78px !important;
}

.pt-79 {
  padding-top: 79px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-81 {
  padding-top: 81px !important;
}

.pt-82 {
  padding-top: 82px !important;
}

.pt-83 {
  padding-top: 83px !important;
}

.pt-84 {
  padding-top: 84px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-86 {
  padding-top: 86px !important;
}

.pt-87 {
  padding-top: 87px !important;
}

.pt-88 {
  padding-top: 88px !important;
}

.pt-89 {
  padding-top: 89px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-91 {
  padding-top: 91px !important;
}

.pt-92 {
  padding-top: 92px !important;
}

.pt-93 {
  padding-top: 93px !important;
}

.pt-94 {
  padding-top: 94px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-96 {
  padding-top: 96px !important;
}

.pt-97 {
  padding-top: 97px !important;
}

.pt-98 {
  padding-top: 98px !important;
}

.pt-99 {
  padding-top: 99px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/* ----------- */
/* PADDING-BOTTOM */
/* ----------- */

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-9 {
  padding-bottom: 9px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pb-19 {
  padding-bottom: 19px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pb-29 {
  padding-bottom: 29px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-31 {
  padding-bottom: 31px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pb-37 {
  padding-bottom: 37px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-41 {
  padding-bottom: 41px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.pb-43 {
  padding-bottom: 43px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.pb-47 {
  padding-bottom: 47px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pb-49 {
  padding-bottom: 49px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-51 {
  padding-bottom: 51px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pb-53 {
  padding-bottom: 53px !important;
}

.pb-54 {
  padding-bottom: 54px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pb-57 {
  padding-bottom: 57px !important;
}

.pb-58 {
  padding-bottom: 58px !important;
}

.pb-59 {
  padding-bottom: 59px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-61 {
  padding-bottom: 61px !important;
}

.pb-62 {
  padding-bottom: 62px !important;
}

.pb-63 {
  padding-bottom: 63px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-66 {
  padding-bottom: 66px !important;
}

.pb-67 {
  padding-bottom: 67px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pb-69 {
  padding-bottom: 69px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-71 {
  padding-bottom: 71px !important;
}

.pb-72 {
  padding-bottom: 72px !important;
}

.pb-73 {
  padding-bottom: 73px !important;
}

.pb-74 {
  padding-bottom: 74px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-76 {
  padding-bottom: 76px !important;
}

.pb-77 {
  padding-bottom: 77px !important;
}

.pb-78 {
  padding-bottom: 78px !important;
}

.pb-79 {
  padding-bottom: 79px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-81 {
  padding-bottom: 81px !important;
}

.pb-82 {
  padding-bottom: 82px !important;
}

.pb-83 {
  padding-bottom: 83px !important;
}

.pb-84 {
  padding-bottom: 84px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-86 {
  padding-bottom: 86px !important;
}

.pb-87 {
  padding-bottom: 87px !important;
}

.pb-88 {
  padding-bottom: 88px !important;
}

.pb-89 {
  padding-bottom: 89px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-91 {
  padding-bottom: 91px !important;
}

.pb-92 {
  padding-bottom: 92px !important;
}

.pb-93 {
  padding-bottom: 93px !important;
}

.pb-94 {
  padding-bottom: 94px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-96 {
  padding-bottom: 96px !important;
}

.pb-97 {
  padding-bottom: 97px !important;
}

.pb-98 {
  padding-bottom: 98px !important;
}

.pb-99 {
  padding-bottom: 99px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

/* ----------- */
/* PADDING-LEFT */
/* ----------- */

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pl-19 {
  padding-left: 19px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-21 {
  padding-left: 21px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pl-23 {
  padding-left: 23px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.pl-27 {
  padding-left: 27px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pl-29 {
  padding-left: 29px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pl-37 {
  padding-left: 37px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.pl-39 {
  padding-left: 39px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-41 {
  padding-left: 41px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.pl-43 {
  padding-left: 43px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pl-49 {
  padding-left: 49px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-51 {
  padding-left: 51px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.pl-53 {
  padding-left: 53px !important;
}

.pl-54 {
  padding-left: 54px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.pl-57 {
  padding-left: 57px !important;
}

.pl-58 {
  padding-left: 58px !important;
}

.pl-59 {
  padding-left: 59px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-61 {
  padding-left: 61px !important;
}

.pl-62 {
  padding-left: 62px !important;
}

.pl-63 {
  padding-left: 63px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-66 {
  padding-left: 66px !important;
}

.pl-67 {
  padding-left: 67px !important;
}

.pl-68 {
  padding-left: 68px !important;
}

.pl-69 {
  padding-left: 69px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-71 {
  padding-left: 71px !important;
}

.pl-72 {
  padding-left: 72px !important;
}

.pl-73 {
  padding-left: 73px !important;
}

.pl-74 {
  padding-left: 74px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-76 {
  padding-left: 76px !important;
}

.pl-77 {
  padding-left: 77px !important;
}

.pl-78 {
  padding-left: 78px !important;
}

.pl-79 {
  padding-left: 79px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-81 {
  padding-left: 81px !important;
}

.pl-82 {
  padding-left: 82px !important;
}

.pl-83 {
  padding-left: 83px !important;
}

.pl-84 {
  padding-left: 84px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-86 {
  padding-left: 86px !important;
}

.pl-87 {
  padding-left: 87px !important;
}

.pl-88 {
  padding-left: 88px !important;
}

.pl-89 {
  padding-left: 89px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-91 {
  padding-left: 91px !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.pl-93 {
  padding-left: 93px !important;
}

.pl-94 {
  padding-left: 94px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-96 {
  padding-left: 96px !important;
}

.pl-97 {
  padding-left: 97px !important;
}

.pl-98 {
  padding-left: 98px !important;
}

.pl-99 {
  padding-left: 99px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

/* ----------- */
/* PADDING-RIGHT */
/* ----------- */

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-9 {
  padding-right: 9px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-11 {
  padding-right: 11px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.pr-19 {
  padding-right: 19px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-21 {
  padding-right: 21px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.pr-23 {
  padding-right: 23px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.pr-27 {
  padding-right: 27px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pr-29 {
  padding-right: 29px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-31 {
  padding-right: 31px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-33 {
  padding-right: 33px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pr-37 {
  padding-right: 37px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.pr-39 {
  padding-right: 39px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-41 {
  padding-right: 41px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.pr-43 {
  padding-right: 43px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.pr-47 {
  padding-right: 47px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pr-49 {
  padding-right: 49px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-51 {
  padding-right: 51px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.pr-53 {
  padding-right: 53px !important;
}

.pr-54 {
  padding-right: 54px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.pr-57 {
  padding-right: 57px !important;
}

.pr-58 {
  padding-right: 58px !important;
}

.pr-59 {
  padding-right: 59px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-61 {
  padding-right: 61px !important;
}

.pr-62 {
  padding-right: 62px !important;
}

.pr-63 {
  padding-right: 63px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-66 {
  padding-right: 66px !important;
}

.pr-67 {
  padding-right: 67px !important;
}

.pr-68 {
  padding-right: 68px !important;
}

.pr-69 {
  padding-right: 69px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-71 {
  padding-right: 71px !important;
}

.pr-72 {
  padding-right: 72px !important;
}

.pr-73 {
  padding-right: 73px !important;
}

.pr-74 {
  padding-right: 74px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-76 {
  padding-right: 76px !important;
}

.pr-77 {
  padding-right: 77px !important;
}

.pr-78 {
  padding-right: 78px !important;
}

.pr-79 {
  padding-right: 79px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-81 {
  padding-right: 81px !important;
}

.pr-82 {
  padding-right: 82px !important;
}

.pr-83 {
  padding-right: 83px !important;
}

.pr-84 {
  padding-right: 84px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-86 {
  padding-right: 86px !important;
}

.pr-87 {
  padding-right: 87px !important;
}

.pr-88 {
  padding-right: 88px !important;
}

.pr-89 {
  padding-right: 89px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-91 {
  padding-right: 91px !important;
}

.pr-92 {
  padding-right: 92px !important;
}

.pr-93 {
  padding-right: 93px !important;
}

.pr-94 {
  padding-right: 94px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-96 {
  padding-right: 96px !important;
}

.pr-97 {
  padding-right: 97px !important;
}

.pr-98 {
  padding-right: 98px !important;
}

.pr-99 {
  padding-right: 99px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MARGINS */
/* ------------------------------------- */
/* ------------------------------------- */

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-11 {
  margin: 11px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-13 {
  margin: 13px !important;
}

.m-14 {
  margin: 14px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-17 {
  margin: 17px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-19 {
  margin: 19px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-21 {
  margin: 21px !important;
}

.m-22 {
  margin: 22px !important;
}

.m-23 {
  margin: 23px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-26 {
  margin: 26px !important;
}

.m-27 {
  margin: 27px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-29 {
  margin: 29px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-31 {
  margin: 31px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-33 {
  margin: 33px !important;
}

.m-34 {
  margin: 34px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-37 {
  margin: 37px !important;
}

.m-38 {
  margin: 38px !important;
}

.m-39 {
  margin: 39px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-41 {
  margin: 41px !important;
}

.m-42 {
  margin: 42px !important;
}

.m-43 {
  margin: 43px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-46 {
  margin: 46px !important;
}

.m-47 {
  margin: 47px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-49 {
  margin: 49px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-51 {
  margin: 51px !important;
}

.m-52 {
  margin: 52px !important;
}

.m-53 {
  margin: 53px !important;
}

.m-54 {
  margin: 54px !important;
}

.m-55 {
  margin: 55px !important;
}

.m-56 {
  margin: 56px !important;
}

.m-57 {
  margin: 57px !important;
}

.m-58 {
  margin: 58px !important;
}

.m-59 {
  margin: 59px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-61 {
  margin: 61px !important;
}

.m-62 {
  margin: 62px !important;
}

.m-63 {
  margin: 63px !important;
}

.m-64 {
  margin: 64px !important;
}

.m-65 {
  margin: 65px !important;
}

.m-66 {
  margin: 66px !important;
}

.m-67 {
  margin: 67px !important;
}

.m-68 {
  margin: 68px !important;
}

.m-69 {
  margin: 69px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-71 {
  margin: 71px !important;
}

.m-72 {
  margin: 72px !important;
}

.m-73 {
  margin: 73px !important;
}

.m-74 {
  margin: 74px !important;
}

.m-75 {
  margin: 75px !important;
}

.m-76 {
  margin: 76px !important;
}

.m-77 {
  margin: 77px !important;
}

.m-78 {
  margin: 78px !important;
}

.m-79 {
  margin: 79px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-81 {
  margin: 81px !important;
}

.m-82 {
  margin: 82px !important;
}

.m-83 {
  margin: 83px !important;
}

.m-84 {
  margin: 84px !important;
}

.m-85 {
  margin: 85px !important;
}

.m-86 {
  margin: 86px !important;
}

.m-87 {
  margin: 87px !important;
}

.m-88 {
  margin: 88px !important;
}

.m-89 {
  margin: 89px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-91 {
  margin: 91px !important;
}

.m-92 {
  margin: 92px !important;
}

.m-93 {
  margin: 93px !important;
}

.m-94 {
  margin: 94px !important;
}

.m-95 {
  margin: 95px !important;
}

.m-96 {
  margin: 96px !important;
}

.m-97 {
  margin: 97px !important;
}

.m-98 {
  margin: 98px !important;
}

.m-99 {
  margin: 99px !important;
}

.m-100 {
  margin: 100px !important;
}

/* ----------- */
/* MARGIN-TOP */
/* ----------- */

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-21 {
  margin-top: 21px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mt-23 {
  margin-top: 23px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-29 {
  margin-top: 29px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-31 {
  margin-top: 31px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-37 {
  margin-top: 37px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-41 {
  margin-top: 41px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.mt-43 {
  margin-top: 43px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.mt-47 {
  margin-top: 47px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-49 {
  margin-top: 49px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-51 {
  margin-top: 51px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.mt-53 {
  margin-top: 53px !important;
}

.mt-54 {
  margin-top: 54px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mt-57 {
  margin-top: 57px !important;
}

.mt-58 {
  margin-top: 58px !important;
}

.mt-59 {
  margin-top: 59px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-61 {
  margin-top: 61px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mt-63 {
  margin-top: 63px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-66 {
  margin-top: 66px !important;
}

.mt-67 {
  margin-top: 67px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mt-69 {
  margin-top: 69px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-71 {
  margin-top: 71px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mt-73 {
  margin-top: 73px !important;
}

.mt-74 {
  margin-top: 74px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-76 {
  margin-top: 76px !important;
}

.mt-77 {
  margin-top: 77px !important;
}

.mt-78 {
  margin-top: 78px !important;
}

.mt-79 {
  margin-top: 79px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-81 {
  margin-top: 81px !important;
}

.mt-82 {
  margin-top: 82px !important;
}

.mt-83 {
  margin-top: 83px !important;
}

.mt-84 {
  margin-top: 84px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-86 {
  margin-top: 86px !important;
}

.mt-87 {
  margin-top: 87px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.mt-89 {
  margin-top: 89px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-91 {
  margin-top: 91px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.mt-93 {
  margin-top: 93px !important;
}

.mt-94 {
  margin-top: 94px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-96 {
  margin-top: 96px !important;
}

.mt-97 {
  margin-top: 97px !important;
}

.mt-98 {
  margin-top: 98px !important;
}

.mt-99 {
  margin-top: 99px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

/* ----------- */
/* MARGIN-BOTTOM */
/* ----------- */

.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-11 {
  margin-bottom: 11px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-21 {
  margin-bottom: 21px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.mb-23 {
  margin-bottom: 23px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-31 {
  margin-bottom: 31px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-37 {
  margin-bottom: 37px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.mb-39 {
  margin-bottom: 39px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-41 {
  margin-bottom: 41px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.mb-47 {
  margin-bottom: 47px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-49 {
  margin-bottom: 49px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-51 {
  margin-bottom: 51px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.mb-53 {
  margin-bottom: 53px !important;
}

.mb-54 {
  margin-bottom: 54px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.mb-57 {
  margin-bottom: 57px !important;
}

.mb-58 {
  margin-bottom: 58px !important;
}

.mb-59 {
  margin-bottom: 59px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-61 {
  margin-bottom: 61px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mb-63 {
  margin-bottom: 63px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-66 {
  margin-bottom: 66px !important;
}

.mb-67 {
  margin-bottom: 67px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.mb-69 {
  margin-bottom: 69px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-71 {
  margin-bottom: 71px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.mb-73 {
  margin-bottom: 73px !important;
}

.mb-74 {
  margin-bottom: 74px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-76 {
  margin-bottom: 76px !important;
}

.mb-77 {
  margin-bottom: 77px !important;
}

.mb-78 {
  margin-bottom: 78px !important;
}

.mb-79 {
  margin-bottom: 79px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-81 {
  margin-bottom: 81px !important;
}

.mb-82 {
  margin-bottom: 82px !important;
}

.mb-83 {
  margin-bottom: 83px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-86 {
  margin-bottom: 86px !important;
}

.mb-87 {
  margin-bottom: 87px !important;
}

.mb-88 {
  margin-bottom: 88px !important;
}

.mb-89 {
  margin-bottom: 89px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-91 {
  margin-bottom: 91px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.mb-93 {
  margin-bottom: 93px !important;
}

.mb-94 {
  margin-bottom: 94px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-96 {
  margin-bottom: 96px !important;
}

.mb-97 {
  margin-bottom: 97px !important;
}

.mb-98 {
  margin-bottom: 98px !important;
}

.mb-99 {
  margin-bottom: 99px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

/* ----------- */
/* MARGIN-LEFT */
/* ----------- */

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.ml-19 {
  margin-left: 19px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-21 {
  margin-left: 21px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.ml-23 {
  margin-left: 23px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.ml-29 {
  margin-left: 29px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-31 {
  margin-left: 31px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.ml-39 {
  margin-left: 39px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-41 {
  margin-left: 41px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.ml-43 {
  margin-left: 43px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.ml-47 {
  margin-left: 47px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-51 {
  margin-left: 51px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.ml-53 {
  margin-left: 53px !important;
}

.ml-54 {
  margin-left: 54px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.ml-57 {
  margin-left: 57px !important;
}

.ml-58 {
  margin-left: 58px !important;
}

.ml-59 {
  margin-left: 59px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-61 {
  margin-left: 61px !important;
}

.ml-62 {
  margin-left: 62px !important;
}

.ml-63 {
  margin-left: 63px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-66 {
  margin-left: 66px !important;
}

.ml-67 {
  margin-left: 67px !important;
}

.ml-68 {
  margin-left: 68px !important;
}

.ml-69 {
  margin-left: 69px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-71 {
  margin-left: 71px !important;
}

.ml-72 {
  margin-left: 72px !important;
}

.ml-73 {
  margin-left: 73px !important;
}

.ml-74 {
  margin-left: 74px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-76 {
  margin-left: 76px !important;
}

.ml-77 {
  margin-left: 77px !important;
}

.ml-78 {
  margin-left: 78px !important;
}

.ml-79 {
  margin-left: 79px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-81 {
  margin-left: 81px !important;
}

.ml-82 {
  margin-left: 82px !important;
}

.ml-83 {
  margin-left: 83px !important;
}

.ml-84 {
  margin-left: 84px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-86 {
  margin-left: 86px !important;
}

.ml-87 {
  margin-left: 87px !important;
}

.ml-88 {
  margin-left: 88px !important;
}

.ml-89 {
  margin-left: 89px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-91 {
  margin-left: 91px !important;
}

.ml-92 {
  margin-left: 92px !important;
}

.ml-93 {
  margin-left: 93px !important;
}

.ml-94 {
  margin-left: 94px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-96 {
  margin-left: 96px !important;
}

.ml-97 {
  margin-left: 97px !important;
}

.ml-98 {
  margin-left: 98px !important;
}

.ml-99 {
  margin-left: 99px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

/* ----------- */
/* MARGIN-RIGHT */
/* ----------- */

.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-21 {
  margin-right: 21px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mr-29 {
  margin-right: 29px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-31 {
  margin-right: 31px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mr-37 {
  margin-right: 37px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.mr-39 {
  margin-right: 39px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-41 {
  margin-right: 41px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.mr-43 {
  margin-right: 43px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.mr-47 {
  margin-right: 47px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mr-49 {
  margin-right: 49px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-51 {
  margin-right: 51px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.mr-53 {
  margin-right: 53px !important;
}

.mr-54 {
  margin-right: 54px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.mr-57 {
  margin-right: 57px !important;
}

.mr-58 {
  margin-right: 58px !important;
}

.mr-59 {
  margin-right: 59px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-61 {
  margin-right: 61px !important;
}

.mr-62 {
  margin-right: 62px !important;
}

.mr-63 {
  margin-right: 63px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-66 {
  margin-right: 66px !important;
}

.mr-67 {
  margin-right: 67px !important;
}

.mr-68 {
  margin-right: 68px !important;
}

.mr-69 {
  margin-right: 69px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-71 {
  margin-right: 71px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.mr-73 {
  margin-right: 73px !important;
}

.mr-74 {
  margin-right: 74px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-76 {
  margin-right: 76px !important;
}

.mr-77 {
  margin-right: 77px !important;
}

.mr-78 {
  margin-right: 78px !important;
}

.mr-79 {
  margin-right: 79px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-81 {
  margin-right: 81px !important;
}

.mr-82 {
  margin-right: 82px !important;
}

.mr-83 {
  margin-right: 83px !important;
}

.mr-84 {
  margin-right: 84px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-86 {
  margin-right: 86px !important;
}

.mr-87 {
  margin-right: 87px !important;
}

.mr-88 {
  margin-right: 88px !important;
}

.mr-89 {
  margin-right: 89px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-91 {
  margin-right: 91px !important;
}

.mr-92 {
  margin-right: 92px !important;
}

.mr-93 {
  margin-right: 93px !important;
}

.mr-94 {
  margin-right: 94px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-96 {
  margin-right: 96px !important;
}

.mr-97 {
  margin-right: 97px !important;
}

.mr-98 {
  margin-right: 98px !important;
}

.mr-99 {
  margin-right: 99px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* FONT-SIZES */
/* ------------------------------------- */
/* ------------------------------------- */

.f-0 {
  font-size: 0px !important;
}

.f-1 {
  font-size: 1px !important;
}

.f-2 {
  font-size: 2px !important;
}

.f-3 {
  font-size: 3px !important;
}

.f-4 {
  font-size: 4px !important;
}

.f-5 {
  font-size: 5px !important;
}

.f-6 {
  font-size: 6px !important;
}

.f-7 {
  font-size: 7px !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-25 {
  font-size: 25px !important;
}

.f-26 {
  font-size: 26px !important;
}

.f-27 {
  font-size: 27px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-29 {
  font-size: 29px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-31 {
  font-size: 31px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-33 {
  font-size: 33px !important;
}

.f-34 {
  font-size: 34px !important;
}

.f-35 {
  font-size: 35px !important;
}

.f-36 {
  font-size: 36px !important;
}

.f-37 {
  font-size: 37px !important;
}

.f-38 {
  font-size: 38px !important;
}

.f-39 {
  font-size: 39px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-41 {
  font-size: 41px !important;
}

.f-42 {
  font-size: 42px !important;
}

.f-43 {
  font-size: 43px !important;
}

.f-44 {
  font-size: 44px !important;
}

.f-45 {
  font-size: 45px !important;
}

.f-46 {
  font-size: 46px !important;
}

.f-47 {
  font-size: 47px !important;
}

.f-48 {
  font-size: 48px !important;
}

.f-49 {
  font-size: 49px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-51 {
  font-size: 51px !important;
}

.f-52 {
  font-size: 52px !important;
}

.f-53 {
  font-size: 53px !important;
}

.f-54 {
  font-size: 54px !important;
}

.f-55 {
  font-size: 55px !important;
}

.f-56 {
  font-size: 56px !important;
}

.f-57 {
  font-size: 57px !important;
}

.f-58 {
  font-size: 58px !important;
}

.f-59 {
  font-size: 59px !important;
}

.f-60 {
  font-size: 60px !important;
}

.f-61 {
  font-size: 61px !important;
}

.f-62 {
  font-size: 62px !important;
}

.f-63 {
  font-size: 63px !important;
}

.f-64 {
  font-size: 64px !important;
}

.f-65 {
  font-size: 65px !important;
}

.f-66 {
  font-size: 66px !important;
}

.f-67 {
  font-size: 67px !important;
}

.f-68 {
  font-size: 68px !important;
}

.f-69 {
  font-size: 69px !important;
}

.f-70 {
  font-size: 70px !important;
}

.f-71 {
  font-size: 71px !important;
}

.f-72 {
  font-size: 72px !important;
}

.f-73 {
  font-size: 73px !important;
}

.f-74 {
  font-size: 74px !important;
}

.f-75 {
  font-size: 75px !important;
}

.f-76 {
  font-size: 76px !important;
}

.f-77 {
  font-size: 77px !important;
}

.f-78 {
  font-size: 78px !important;
}

.f-79 {
  font-size: 79px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-81 {
  font-size: 81px !important;
}

.f-82 {
  font-size: 82px !important;
}

.f-83 {
  font-size: 83px !important;
}

.f-84 {
  font-size: 84px !important;
}

.f-85 {
  font-size: 85px !important;
}

.f-86 {
  font-size: 86px !important;
}

.f-87 {
  font-size: 87px !important;
}

.f-88 {
  font-size: 88px !important;
}

.f-89 {
  font-size: 89px !important;
}

.f-90 {
  font-size: 90px !important;
}

.f-91 {
  font-size: 91px !important;
}

.f-92 {
  font-size: 92px !important;
}

.f-93 {
  font-size: 93px !important;
}

.f-94 {
  font-size: 94px !important;
}

.f-95 {
  font-size: 95px !important;
}

.f-96 {
  font-size: 96px !important;
}

.f-97 {
  font-size: 97px !important;
}

.f-98 {
  font-size: 98px !important;
}

.f-99 {
  font-size: 99px !important;
}

.f-100 {
  font-size: 100px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MEDIA QUERIES */
/* ------------------------------------- */
/* ------------------------------------- */

@media screen and (max-width: 640px) {
  .mobile-hidden {
    display: none !important;
  }
}
