.loading_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #830351;
}

.loading_div .ant-spin {
  font-size: 30px;
}

.loading_div .ant-spin .ant-spin-dot-item {
  background-color: #f8ad2f;
}
