@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&display=swap");

.admin_layout_content {
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.admin_layout .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  height: 45px;
}

.user_header {
  color: #fff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_header .anticon {
  font-size: 20px;
  margin-right: 8px;
}

.logout_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout_header button {
  color: #fff;
}

.logout_header button:hover {
  opacity: 0.7;
  color: #fff !important;
}

.logout_header .anticon {
  font-size: 20px;
}

.ant-menu-item-group-title {
  color: #807f46 !important;
}

.ant-menu-item-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu
  .ant-menu-item-group
  .ant-menu-item-group-list
  .ant-menu-submenu-title {
  padding-inline: 16px !important;
}

.ant-menu-sub .ant-menu-item {
  padding-inline: 24px !important;
}
